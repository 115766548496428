import React from "react"

import { Link } from "gatsby"
import Layout from "../../components/layoutEn"
import SEO from "../../components/layout/seo"

import standProjMain from "../../img/standProjMain.png"
import standMainImg01 from "../../img/t_28.jpg"
import standMainImg02 from "../../img/t_27.jpg"
import standMainImg03 from "../../img/connectivity.jpg"
import Itut27 from "../../img/ITUT27.jpg"
import Itut28 from "../../img/ITUT28.jpg"

const InterStand = () => (
  <Layout>
    <SEO title="M2MTech" />
    <div className="interStandWrap">
      <div className="sectionWrap StandOverview">
        <p className="standProjMain">
          <img src={standProjMain} alt="" />
        </p>
        <div className="textWrap">
          <h2>
            International <span>standard</span>
          </h2>
          <h3 style={{ paddingTop: 20 }}>ITU-T SG20 Series Y</h3>
          <p className="bodyText">
            M2MTech , in cooperation with the Korea Electronics and
            Telecommunications Research Institute, is participating in the
            development of standards for the Internet of Things and Smart City
            technology in the International Standards Organization ITU-T SG20
            (Internet of Things and Smart Cities). An international standard
            applied to the Internet of Things (IoT) for elevators and lift
            devices was proposed and adopted, and the approval process is
            underway. M2MTech 's efforts are expected to be helpful in
            researching the relationship between smart city and community
            (SC&amp;C), and it is expected to contribute to planning the
            practical application of future smart city solutions that are
            becoming a hot topic not only in Korea but also worldwide.
          </p>
        </div>
      </div>
      <div className="sectionWrap itu">
        <div className="textWrap">
          <h2>ITU-T SG20 Series Y</h2>
          <p>
            <img src={standMainImg03} alt="" />
          </p>
          <p className="bodyText">
            The International Telecommunication Union (ITU) is the United
            Nations specialized agency in the field of telecommunications and
            information and communication technology (ICT). The ITU
            Telecommunication Standardization Sector (ITU-T) is a permanent body
            of the ITU, responsible for researching technical, operational and
            customs issues and issuing recommendations aimed at global
            telecommunication standardization.
            <br />
            <br />
            M2MTech and Electronics and Telecommunications Research Institute
            (ETRI) are collaborating on the development of standards for ITU-T
            SG 20 as strategic cooperative partners in technology research in
            the Internet of Things and AI fields. M2MTech 's smart elevator
            solution with ETRI was contributed to the ITU-T Y-series (supplement
            56) published in December 2019.
          </p>
        </div>
      </div>
      <div className="sectionWrap ituContribution">
        <div className="textWrap">
          <h2>ITU-T SG20 contribution</h2>
          <p className="bodyText">
            “Framework of IoT based monitoring and management for Lift”
            <br />
            Due to most lifts not being able to connect directly to the
            telecommunication network, they have applied their own non-uniform
            data format and interconnection protocol of the telecommunication
            network. As a result of this, interoperability problems constantly
            arise, so M2MTech recognized the need to apply common protocols and
            data formats, and built a gateway and a web-serviced lift monitoring
            system to support interconnection.
          </p>
        </div>
        <div className="standMainImg">
          <p className="standMainImg01">
            <img src={standMainImg01} alt="" />
          </p>
          <p className="standMainImg02">
            <img src={standMainImg02} alt="" />
          </p>
        </div>
      </div>
      <div className="sectionWrap standServices">
        <div className="textWrapMain">
          ITU-T SG20 Y-series (supplement 56) page.21 Contributions
        </div>
        <h3>8.5 Lift monitoring service </h3>
        <ul>
          <li>
            1. Title of the use case (title is strictly related to the
            application area addressed)
            <span>a. Name of the use case: Lift monitoring services </span>
            <span>
              b. ID of the use case (IDs are given in ID category 2
              (Infrastructure) of clause 7);
            </span>
            <span>
              c. Version/revision history (such as no./month/year): 1.0
            </span>
            <span>
              d. Source (Country/ITU-T member/Organization registered with ITU):
              Korea (Republic of)/ETRI
            </span>
          </li>
          <li>
            2. Objective of the use case (aligned with title that has an
            explanatory content) This use case describes maintenance and safety
            services for elevators in a smart city
          </li>
          <li>
            3. Background
            <span>
              a. Current practice (current process/context which will benefit
              from the implementation of the use case) Currently, an
              administrator or an inspector performs lift safety inspection ,
              danger is detected by the user's report, and emergency warning is
              activated.
            </span>
            <span>
              b. Need for use case <br /> When managing lifts, it is possible to
              conduct safety and status check remotely by using a monitoring
              system. It is also possible to control when an individual is
              trapped in the elevator, or when a problem such as failure or
              stoppage occurs. Therefore, smart city operators manage smart city
              safely and conveniently using lift monitoring systems.
            </span>
            <span>c. Country ecosystem specifics: None</span>
          </li>
          <li>
            4. City or community of the use case
            <span>a. City or community name: Seoul metropolitan city</span>
            <span>b. Country: Korea (Republic of) </span>
            <span>c. Region: Asia </span>
            <span>d. Population of the City: 10,124,579 (December 2017) </span>
            <span>
              e. Phase of the use case (Planning phase/Pilot phase/Commercial
              phase): Commercial phase
            </span>
          </li>
          <li>
            5. Description
            <span>
              a. Ecosystem description in terms of actors and business roles
              Smart city operation centre is connected with lift and management
              office. The smart city operation centre requests lift monitoring
              information from the lift administrator or management office and
              shares lift monitoring information. <br />
              NOTE – The main role of the smart city operation centre in this
              use case is to monitor and control the lifts throughout the city.
            </span>
            <span>
              b. Contextual illustration <br />
              For lift management and safety purposes, the smart city operation
              centre performs real-time monitoring, control, diagnosis, and
              management functions and provides statistical information.
              <br />
              <br />
              <img src={Itut27} alt="" />
              <br />
              Figure 8-9 – Interworking between smart city operation centre and
              lift system
            </span>
            <span>c. Pre-requisites: None </span>
            <span>
              d. Pre-conditions (if any) <br />
              The applications used by users and engineers are different. Using
              the application which is made available in the users' devices,
              users provide location information updates to the smart city
              operation centre and receive nearby lift failure information. The
              smart city operation centre notifies the engineers located at the
              shortest distance from the failed lift.
            </span>
            <span>
              e. Triggers: When an emergency alarm, out of service (fault) or
              fire operation is reported.
            </span>
            <span>
              f. Scenario
              <span>
                1) Interworking between smart city operation centre and lift
                system <br />− If an emergency alarm, out of service or fire
                operation event is detected in the elevator. An event is sent to
                the smart city operation centre. <br />− Smart city operation
                centre sends events to nearby users to prevent further
                accidents, or to engineers to repair faults. <br />− The
                engineers complete the inspection of the event, send a
                completion message to the smart city operation centre. The smart
                city operation centre receives messages from engineers, or
                receives complete events directly from the lift system.
              </span>
            </span>
            <span>
              g. Process flow diagram <br />
              1) Interworking between a smart city operation centre and a lift
              system (see Figure 8-10)
              <br />
              <img src={Itut28} alt="" />
              <br />
              Figure 8-10 – Process flow of interworking between a smart city
              operation centre and a lift system
            </span>
            <span>h. Post-conditions (if any): None </span>
            <span>
              i. Information exchange <br />
              The lift is managed based on the lift registration number, and the
              location information is stored therein. The location information
              of the lift system and fault related information are exchanged.
              <br />
              The smart city operation centre sends a fault alarm to users near
              a broken lift, and sends information regarding the fault to the
              engineers closest to the lift.
            </span>
          </li>
          <li>
            6. Architectural considerations
            <span>
              a. Deployment considerations
              <br /> Equipment such as Internet of things (IoT) gateways for
              remote monitoring in lifts should be supported. Standard protocols
              and data formats for event transmission should be defined.
            </span>
            <span>b. Geographical considerations: None </span>
            <span>
              c. Communication infrastructure <br /> Lifts communicate with
              monitoring system across wired or wireless communication links.
            </span>
            <span>d. Performance criteria: None </span>
            <span>e. Interface requirements: None</span>
            <span>f. User interface: None </span>
            <span>
              g. APIs to be exposed to the application from platform: None{" "}
            </span>
            <span>
              h. Data management: The lift number is unique and consists of
              seven digits. The first two digits indicate the area and lift
              type. Then, the rest of digits is allocated in order and match
              with the specific address information and other lift related
              information such as lift specification.
            </span>
            <span>i. Data backup, archiving and recovery: None</span>
            <span>j. Remote device management: None</span>
            <span>k. Start-up/Shutdown process: None</span>
            <span>
              l. Security requirements <br /> The collected data must be stored
              and handled in a secure environment.
            </span>
          </li>
          <li>7. Potential market growth forecast: None </li>
          <li>
            8. Implementation constraints (for the support of the use case):
            None{" "}
          </li>
          <li>9. Statutory compliances and related regulations: None </li>
          <li>10. Available International Standards: None </li>
          <li>
            11. References (related to standards or other useful information):
            https://unicorn.m2mtech.co.kr (Korean only)
          </li>
          <li>12. General remarks: None</li>
        </ul>
      </div>
      <div className="sectionWrap standGallery">
        -<br />
        https://unicorn.m2mtech.co.kr is the initial version of ELSA.CLOUD,
        which is changed to <b>https://elsa.cloud</b> (Korean/English). Please
        visit <Link to="/ElsaEn/">https://m2mtech.co.kr/ElsaEn</Link> or send
        email to "info@m2mtech.co.kr" for more information.
      </div>
    </div>
  </Layout>
)

export default InterStand
